/**
 * @license
 * MyFonts Webfont Build ID 4111228, 2021-06-28T10:41:04-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: SofiaPro-Regular by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/regular/
 * 
 * Webfont: SofiaPro-Regularitalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/regular-italic/
 * 
 * Webfont: SofiaPro-Medium by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/medium/
 * 
 * Webfont: SofiaPro-SemiBold by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/semi-bold/
 * 
 * Webfont: SofiaPro-Bold by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/bold/
 * 
 * 
 * Webfonts copyright: Copyright © Olivier Gourvat - Mostardesign Type Foundry, 2020. All rights reserved.
 * 
 * © 2021 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3ebb7c");

@font-face {
  font-family: "SofiaPro";
  src: url('/webFonts/SofiaProRegular/font.woff2') format('woff2'), 
  url('/webFonts/SofiaProRegular/font.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "SofiaPro";
  src: url('/webFonts/SofiaProMedium/font.woff2') format('woff2'), 
  url('/webFonts/SofiaProMedium/font.woff') format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "SofiaPro";
  src: url('/webFonts/SofiaProRegularitalic/font.woff2') format('woff2'), 
  url('/webFonts/SofiaProRegularitalic/font.woff') format('woff');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "SofiaPro";
  src: url('webFonts/SofiaProBold/font.woff2') format('woff2'), 
  url('/webFonts/SofiaProBold/font.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "SofiaPro";
  src: url('/webFonts/SofiaProSemiBold/font.woff2') format('woff2'), 
  url('/webFonts/SofiaProSemiBold/font.woff') format('woff');
  font-style: normal;
  font-weight: 600;
}